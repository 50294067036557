import React from "react";

interface Video {
  url: string;
  title: string;
}

interface VideoListProps {
  videos: Video[];
}

const VideoList = (props: VideoListProps) => {
  // const [videos, setVideos] = useState<Video[]>([]);

  // if (props.videos) {
  //   setVideos(props.videos)
  // }

//   useEffect(() => {
//     if (!videos.length) {
// }, [videos.length]);

return (
  <div className='ppvideo-toplevel'>
    {props.videos.map((video) => (
      <div key={video.title} className='ppvideo-container'>
        <hr />
        <p>
          {video.title}
        </p>
        <video className='ppvideo' src={video.url} poster={video.url.replace(/.mp4/g, '.png')} controls />
      </div>
    ))}
  </div>
);
};

export default VideoList;
