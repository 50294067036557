import React, {useEffect} from 'react';
import './App.css';
import VideoList from './VideoList';

const App = () => {
  console.log("trying to fetch")
  const [videos, setVideos] = React.useState([])

  useEffect(  () => {

    fetch("videos.json").then((response) => {
      return response.json()
    }).then((response) => {
      console.log(response)
      setVideos(response)
    })
  }, [videos.length])
  return (
    <div className="App">
      <img src='andrea.jpeg' alt='Andrea Andux-Jones' className='andrea-photo' />
      <header className="App-header">
        <h1>PaperPrinciples.com</h1>
        <div>
          <p>
            Hello!  This site is dedicated to Andrea Andux-Jones.
            <br />
            She passed away on <a href="https://www.sunsetmemory.com/obituaries/Andrea-Andux-Jones/" target='aaobit'>
              November 8, 2014
            </a>, of multiple life-long medical complications.

          </p>
          <p>
            Andrea was a three dimensional artist, making her own origami and jewelry from
            recycled materials. She loved each of her cats as her own children, the beach,
            poetry, and anything British.
          </p>
          <p>
            This site showcases a set of origami videos she made around 2010.  They are originally posted
            on her <a href="https://www.youtube.com/@PaperPrinciples" target='yt'>Youtube's PaperPrinciples</a> channel.
          </p>
          <p>
            She worked for many years in Hillsborough County's "Artists in the Schools" program,
            introducing hundreds of children to the art of origami, literature, and poetry.
          </p>
          <p>
          </p>
          <p>
          </p>
        </div>
      </header >
      <div className='App-body'>
        <VideoList videos={videos}/>
      </div>
    </div>
  );
}

export default App;
